import { NavLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";

const SideBar = (props) => {
  return (
    <div
      className={"flex flex-col shadow-2xl shadow-slate-400 bg-black"}
      style={{ height: "calc(100% - 106px)" }}
    >
      <div>
        {props.isCollapse ? (
          <NavigateBeforeIcon
            className="m-3 float-right cursor-pointer collapse-btn"
            onClick={() => props.setIsCollapse(!props.isCollapse)}
          />
        ) : (
          <NavigateNextIcon
            className="m-3 float-right cursor-pointer collapse-btn"
            onClick={() => props.setIsCollapse(!props.isCollapse)}
          />
        )}
      </div>
      <hr className="border-[#968049]" />
      <div className="sidebarZ" style={{ overflow: "auto" }}>
        {props.menuList &&
          props.menuList.length > 0 &&
          props.menuList.map((menu, index) => {
            return (
              <>
                {menu?.name !== "HOME" && (
                  <Tooltip
                    className="menuItem"
                    key={index}
                    title={!props.isCollapse ? menu?.name.toLowerCase() : ""}
                    placement="right"
                  >
                    <NavLink
                      to={menu?.url}
                      className="flex items-center hover:bg-slate-100"
                    >
                      <div>
                        <div
                          className={"m-3 svgIconStyles"}
                          dangerouslySetInnerHTML={{
                            __html: menu?.materialUIIcon,
                          }}
                        ></div>
                      </div>
                      {props.isCollapse && (
                        <span
                          className={"text-xs font-semibold"}
                        >
                          {menu?.name}
                        </span>
                      )}
                    </NavLink>
                  </Tooltip>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(SideBar);
