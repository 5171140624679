import axios from "axios";
import { requestAccessToken } from "./AuthServices";

export const getCrossReference = async (manufacturer) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/CrossReference/GetCrossReference?manufacturer=${manufacturer}`
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getManufacturers = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/CrossReference/GetManufacturers`
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getMainMenuItems = async (emailId = null) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const endpoint = `/Services/GetMainMenuItems?EmailAddress=${emailId}`;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`, options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getUserTypes = async () => {
  try {
    // let token = localStorage.getItem("token");
    // const headers = new Headers();
    // const bearer = `Bearer ${token}`;

    // headers.append("Authorization", bearer);
    // const options = {
    //   method: "GET",
    //   headers: headers,
    // };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/GetUserTypes`
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const createNewUser = async (body) => {
  // let token = localStorage.getItem("token");
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/Add`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const verifyRecaptcha = async (recaptchaToken) => {
  try {
    // Send a POST request to the Google reCAPTCHA verification endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fusion/CrossReference/VerifyRecaptcha`,
      {
        token: recaptchaToken,
      },
      {
        headers: {
          "Content-Type": "application/json", // Set appropriate headers
        },
      }
    );
    console.log("Response:", response);
    // Check if the reCAPTCHA verification was successful and the score is above 0.5
    if (response.data.success && response.data.score >= 0.5) {
      console.log("reCAPTCHA passed:", response.data);
      return true; // Verification successful
    } else {
      console.log("reCAPTCHA failed:", response.data);
      return false; // Verification failed
    }
  } catch (error) {
    console.error("Error verifying reCAPTCHA:", error);
    return false;
  }
};
